.table-container {
    max-width: 75rem;
    margin: 0 auto;
}

.table {
    font-weight: 300;
    background-color: $white;

    .table-light-grey {
        th, td {
            background-color: #f4f4f4;
        }
    }

    td.wrap-content {
        width: 1px;
        padding: 0.75rem 0.25rem;
        text-align: center;
    }
}
@import "src/@paco/styles/helpers/functions";

%form {
    padding: remCalc(1);
}

%form-row {
    display: flex;
    width: 100%;
}

%form-col {
    flex: 1;
    padding: remCalc(.375) remCalc(1);
}

.btn {
    min-width: 136px;
    height: 46px;
    border-radius: 2px;
    box-shadow: 0 2px 12px 0 rgba(0,0,0,0.1);
    border: none;
    font-weight: 600;

    &-orange {
        background-color: $orange;
        color: $white;

        &:hover:not([disabled]) {
            background-color: $orange-dark;
            animation: background-color 0.45s;
        }
    }

    &-orange-text {
        color: $orange;
        box-shadow: none;
        background: none;

        &:hover:not([disabled]) {
            color: $orange-dark;
        }
    }

    &-white {
        background-color: $white;
        color: $primary;

        &:hover:not([disabled]) {
            background-color: darken($white, 5%);
            animation: background-color 0.45s;
        }
    }

    &-circle {
        @include clear-button();
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        width: 56px;
        height: 56px;
        padding: 0;
        background: $orange;
        overflow: hidden;
        box-shadow: 0 1px 6px 0 rgba(0,0,0,0.26);
        cursor: pointer;
        color: $white;

        &-small {
            width: 32px;
            height: 32px;
        }

        &-dark {
            background: $dark;
        }

        &:hover {
            text-decoration: none;
        }
    }
}

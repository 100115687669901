@import "theme";

.react-datepicker-wrapper {
  display: block;
}

.react-datepicker__input-container {
  width: 100%;
}

.react-datepicker__input-container .invalid {
  border-color: $red;
}
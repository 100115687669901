@import "button";
@import "flex";
@import "form";
@import "input";
@import "modal";
@import "spinner";

%text-ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

%click-extend {
    position: absolute;
    inset-block-start: 0;
    inset-inline-start: 0;
    width: 100%;
    height: 100%;
}

%row {
    display: flex;
    flex-wrap: wrap;
    margin-right: remCalc(-1);
    margin-left: remCalc(-1);
}

%col {
    position: relative;
    width: 100%;
    padding-right: remCalc(1);
    padding-left: remCalc(1);
}

@import "mixins";

// Main
$font-family: 'Source Sans Pro', sans-serif;
$break-mobile: 750px;
$break-medium: 1024px;
$site-min-width: 275px;
$main-padding: 20px;

// Colors
$dark: #0B2730;
$blue: #66B2FF;
$light-blue: #86C8DF;
$primary: #114D61;
$secondary: #00ACF5;
$white: #fff;
$black: #000;
$light: #ADBECF;
$red: #FF1C25;
$red-dark: #b9191f;
$success: #69C300;
$background: #f1f1f1;
$table_bg: #FBFBFB;
$orange: #FF8100;
$orange-dark: #E57400;
$green-success: #4BB543;

$grey: #666;
$grey-disabled: #CACACA;
$light-grey: #f9f9f9;
$lighter-grey: #f3f3f3;
$border-grey: #E1E1E1;
$medium-grey: #979797;
$placeholder: #ACACAC;

// Typography
$font-size-base: 1rem;
$h1-font-size: $font-size-base * 2;
$h2-font-size: $font-size-base * 1.75;
$h3-font-size: $font-size-base * 1.5;
$h4-font-size: $font-size-base * 1.25;
$h5-font-size: $font-size-base * 1.125;
$h6-font-size: $font-size-base;
$headings-font-weight: bold;
$headings-color: $dark;

// Card
$card-box-shadow: 0 2px 4px 0 rgba(0,0,0,0.06);

// Navbar
$navbar-padding-y: 0;
$nav-link-padding-y: 1.25rem;
$navbar-nav-link-padding-x: 1rem;
$navbar-brand-padding-y: 0;
$navbar-height: 56px;
$navbar-dark-color: $white;
$navbar-dark-hover-color: $white;

$pagination-color: $dark;
$pagination-active-bg: $dark;
$pagination-active-color: $white;
$pagination-hover-color: $dark;

$tooltip-arrow-color: $dark;
$tooltip-bg: $dark;

$gray-600: $medium-grey;
$gray-300: #dee2e6;

// Table
$table-accent-bg: $light-grey;

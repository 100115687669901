@keyframes spin {
    from { transform: rotate(0deg) }
    to { transform: rotate(1turn) }
}

@keyframes showToast {
    0% {
        transform: translateX(40%);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@import "theme";

.form-group {
    label {
        font-weight: 600;
    }

    input {
        font-weight: 500;
        &[readonly], &[disabled] {
            background-color: $white;
            border: 0;
            padding-left: 0;
        }
    }

    .form-fake-field {
        padding: $input-padding-y 0;
        margin-bottom: 0;
    }
}

.info-list-item .form-control {
    border: 1px solid var(--c-border-grey);
}

textarea.form-control {
    color: $black;
    background: $table_bg;;

    &::placeholder {
        color: $placeholder;
    }
}

input[type=date] {
    border: 0;
}

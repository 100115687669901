@import '../helpers/functions';
@import './flex';

%button {
    @extend %flex-align-center;

    margin: 0;
    min-width: remCalc(8.5);
    padding: remCalc(.25);
    font: inherit;
    font-size: remCalc(1.125);
    font-weight: var(--fw-medium);
    line-height: 1.25;
    color: var(--c-primary);
    cursor: pointer;
    border: none;
    background: none;

    &:hover,
    &:focus {
        color: var(--c-primary);
        outline: none;
        text-decoration: none;
    }

    &:disabled {
        color: var(--c-disabled-grey);
        cursor: not-allowed;
    }
}

%button--reset {
    border: none;
    border-radius: 0;
    padding: 0;
    background-color: transparent;
    cursor: pointer;
}

%button--is-primary {
    @extend %button;

    border-radius: 2px;
    padding: remCalc(.625) remCalc(1);
    color: var(--c-white);
    background: var(--c-orange);
    overflow: hidden;

    &:hover,
    &:focus {
        color: var(--c-white);
        background: var(--c-orange-dark);
    }

    &:disabled {
        color: var(--c-white);
        background: var(--c-placeholder);
    }
}

%back-button {
    @extend %button--is-primary;

    color: var(--c-orange);
    background: var(--c-white);
    box-shadow: var(--box-shadow);

    &:hover {
        color: var(--c-orange);
        background: var(--c-light-grey);
    }
}

%nav-tab-button {
    width: 50%;
    height: remCalc(2.875);
    background: var(--c-white);
}

%circle-button {
    @include circle(remCalc(3.5));
    @extend %button;

    color: var(--c-white);
    background-color: var(--c-orange);
    box-shadow: var(--box-shadow);

    &:hover,
    &:focus {
        color: var(--c-white);
    }
}

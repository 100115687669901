.form-control {
    border-radius: 0;
    border: none;
    padding: 10px;
    font-weight: 300;
    font-size: 1em;

    -webkit-appearance: none;
    -webkit-border-radius: 0;

    &::placeholder {
        color: $gray-300;
    }

    &.custom-select {
        line-height: 1;
    }
}

.select-input-holder {
    position: relative;

    &__icon {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        color: $gray-600;
    }
}

.btn-outline-none {

    &:focus {
        outline: none;
    }

    &:hover {
        text-decoration: underline;
    }
}

.form-check {
    padding-left: 0;

    .custom-checkbox {

        .custom-control-label {
            &:before, &:after {
                transform: translateY(-2px);
            }
        }

        .custom-control-input:checked {
            ~ .custom-control-label::before {
                background-color: $dark;
            }
        }
    }
}

@import "../styles/helpers/index";

// Colours
$c-primary:             17, 77, 97;
$c-secondary:           0, 172, 245;
$c-blue:                102, 178, 255;
$c-dark-blue:           11, 39, 48;
$c-light-blue:          134, 200, 223;
$c-white:               255, 255, 255;
$c-pastel-white:        251, 251, 251;
$c-black:               0, 0, 0;
$c-orange:              255, 129, 0;
$c-dark-orange:         229, 116, 0;
$c-green:               105, 195, 0;
$c-red:                 255, 28, 37;
$c-grey:                108, 117, 125;
$c-border-grey:         225, 225, 225;
$c-disabled-grey:       202, 202, 202;
$c-light-grey:          243, 243, 243;
$c-lightest-grey:       249, 249, 249;
$c-placeholder:         172, 172, 172;

:root {
    --c-primary:                    rgb(#{$c-primary});
    --c-primary-rgb:                #{$c-primary};
    --c-secondary:                  rgb(#{$c-secondary});
    --c-blue:                       rgb(#{$c-blue});
    --c-dark-blue:                  rgb(#{$c-dark-blue});
    --c-light-blue:                 rgb(#{$c-light-blue});
    --c-white:                      rgb(#{$c-white});
    --c-pastel-white:               rgb(#{$c-pastel-white});
    --c-black:                      rgb(#{$c-black});
    --c-orange:                     rgb(#{$c-orange});
    --c-orange-dark:                rgb(#{$c-dark-orange});
    --c-green:                      rgb(#{$c-green});
    --c-red:                        rgb(#{$c-red});
    --c-grey:                       rgb(#{$c-grey});
    --c-border-grey:                rgb(#{$c-border-grey});
    --c-light-grey:                 rgb(#{$c-light-grey});
    --c-lightest-grey:              rgb(#{$c-lightest-grey});
    --c-disabled-grey:              rgb(#{$c-disabled-grey});
    --c-placeholder:                rgb(#{$c-placeholder});

    --f-primary:                    'Source Sans Pro', sans-serif;

    --fw-light:                     300;
    --fw-regular:                   400;
    --fw-medium:                    600;
    --fw-bold:                      700;

    --navbar-height:                #{remCalc(3.5)};
    --app-wrapper-width:            #{remCalc(64)};

    --box-shadow:                   0 #{remCalc(.125)} #{remCalc(.75)} 0 rgba(#{$c-black}, .1);
}
